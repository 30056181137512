export const screenWrap = (object) => {
  if (object.x > (object.viewWidth + 100)) {
    object.x = -100
  }
  if (object.x < -100) {
    object.x = object.viewWidth + 100
  }
  if (object.y > (object.viewHeight + 100)) {
    object.y = -100
  }
  if (object.y < -100) {
    object.y = object.viewHeight + 100
  }
}

export const loadSprite = (src) => {
  const sprite = new Image()
  sprite.src = src
  return sprite
}

export const xOrd = (size, direction) => {
  return size * Math.sin(direction / 180 * Math.PI)
}

export const yOrd = (size, direction) => {
  return size * -Math.cos(direction / 180 * Math.PI)
}