import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Asteroids from 'src/components/asteroids'

const NotFoundPage = () => (
  <Layout>
    <SEO
      seo={{
        metaTitle: "404: Not found",
        metaDescription: "Page destroyed by asteroids",
      }}
    />
    <Asteroids />
  </Layout>
);

export default NotFoundPage;
