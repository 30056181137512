import GameObject from '../game-object'
import Asteroid from './Asteroid'
import {screenWrap, xOrd, yOrd} from '../helpers'

class Bullet extends GameObject {
  constructor(x, y, direction) {
    super(x, y);
    this.speed = 800
    this.direction = direction
    this.opacity = 1
  }

  onStep = () => {
    this.x += xOrd(this.speed, this.direction) / this.fps
    this.y += yOrd(this.speed, this.direction) / this.fps

    this.opacity -= 1.5 / this.fps

    if (this.opacity < 0.2) {
      this.destroy()
    }

    screenWrap(this)

    const collision = this.controller.objects.find(
      other => other.constructor === Asteroid &&
        Math.sqrt((this.x - other.x) ** 2 + (this.y - other.y) ** 2) < (other.radius + 2)
    )
    if (collision) {
      this.controller.score += 50 * (40 / collision.radius)
      this.destroy()
      collision.split()
    }
  }

  onDraw(ctx) {
    ctx.beginPath()
    ctx.arc(this.x, this.y, 3, 0, 2 * Math.PI)
    ctx.fillStyle = "#fc0"
    ctx.globalAlpha = this.opacity
    ctx.fill()
  }
}

export default Bullet

