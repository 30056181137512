import GameObject from '../game-object'
import {loadSprite} from 'src/components/asteroids/helpers'
import rocketShipSrc from '../sprites/rocket-ship.svg'

class Lives extends GameObject {
  initialize() {
    this.rocketShipSprite = loadSprite(rocketShipSrc)
  }

  onDraw(ctx) {
    if (this.controller.lives < 0) {
      const text = "Game Over"
      ctx.font = "bold 60px Montserrat"
      ctx.fillStyle = "#aaa"
      const width = Math.min(ctx.measureText(text).width, this.viewWidth * 0.8)
      ctx.fillText(text, (this.viewWidth - width) / 2, (this.viewHeight) / 2, this.viewWidth * 0.8)
    } else {
      ctx.globalAlpha = 0.9
      for (let i = 0; i < this.controller.lives; i++) {
        ctx.drawImage(this.rocketShipSprite, 40 + 50 * i, this.viewWidth >= 1024 ? 40 : (this.viewHeight - 80))
      }
    }
  }
}

export default Lives