import GameObject from '../game-object'


class Message extends GameObject {
  onDraw = (ctx) => {
    const line1 = "Page destroyed by"
    const line2 = "Asteroids"
    const line3 = "(404 not found)"
    ctx.fillStyle = "#ccc"

    ctx.font = "bold 20px Montserrat"
    const line1Width = ctx.measureText(line1).width
    ctx.fillText(line1, (this.viewWidth - line1Width) / 2, 30, this.viewWidth * 0.8)

    ctx.font = "bold 40px Montserrat"
    const line2Width = ctx.measureText(line2).width
    ctx.fillText(line2, (this.viewWidth - line2Width) / 2, 70, this.viewWidth * 0.8)

    ctx.font = "bold 14px Montserrat"
    const line3Width = ctx.measureText(line3).width
    ctx.fillText(line3, (this.viewWidth - line3Width) / 2, 95, this.viewWidth * 0.8)
  }
}

export default Message
