import GameObject from '../game-object'
import {screenWrap, loadSprite, xOrd, yOrd} from '../helpers'
import Asteroid from './Asteroid'
import Bullet from './Bullet'
import Explosion from './Explosion'

import rocketShipSrc from '../sprites/rocket-ship.svg'
import flamesSpriteSrc from '../sprites/flames.svg'

class RocketShip extends GameObject {
  initialize() {
    this.initialX = this.x
    this.initialY = this.y

    this.sprite = loadSprite(rocketShipSrc)
    this.flamesSprite = loadSprite(flamesSpriteSrc)

    this.rotate = 0
    this.hSpeed = 0
    this.vSpeed = 0
    this.acceleration = 400
    this.friction = 0.005
  }

  fire = () => {
    this.createObject(
      new Bullet(
        this.x + xOrd(this.sprite.height / 2, this.rotate),
        this.y + yOrd(this.sprite.height / 2, this.rotate),
        this.rotate
      )
    )
  }

  die = () => {
    this.destroy()
    this.createObject(new Explosion(this.x, this.y))
    if (this.controller.lives > 0) {
      window.setTimeout(() => {
        this.createObject(new RocketShip(this.initialX, this.initialY))
      }, 2000)
    }
    this.controller.lives -= 1
  }

  onStep = () => {
    if (this.pressedKeys.ArrowRight) {
      this.rotate += 360 / this.fps
    }
    if (this.pressedKeys.ArrowLeft) {
      this.rotate -= 360 / this.fps
    }
    if (this.pressedKeys.ArrowUp) {
      this.hSpeed += xOrd(this.acceleration, this.rotate) / this.fps
      this.vSpeed += yOrd(this.acceleration, this.rotate) / this.fps
    }

    if (this.pressedKeys[' ']) {
      if (!this.firedLastStep) {
        this.fire()
      }
      this.firedLastStep = true
    }
    else {
      this.firedLastStep = false
    }

    this.hSpeed = this.hSpeed * (1 - this.friction)
    this.vSpeed = this.vSpeed * (1 - this.friction)

    screenWrap(this)

    this.x += this.hSpeed / this.fps
    this.y += this.vSpeed / this.fps

    const collision = this.controller.objects.find(
      other => other.constructor === Asteroid &&
        Math.sqrt((this.x - other.x) ** 2 + (this.y - other.y) ** 2) < (other.radius + this.sprite.width / 2)
    )
    if (collision) {
      this.die()
      collision.split()
    }
  }

  onDraw = (ctx) => {
    ctx.translate(this.x, this.y)
    ctx.rotate(this.rotate / 180 * Math.PI)
    ctx.drawImage(this.sprite, -this.sprite.width / 2, -this.sprite.height / 2)
    if (this.pressedKeys.ArrowUp) {
      ctx.drawImage(this.flamesSprite, -this.flamesSprite.width / 2, this.sprite.height / 2 + 4)
    }
  }
}

export default RocketShip
