import GameObject from '../game-object'
import {screenWrap, xOrd, yOrd} from '../helpers'

class Asteroid extends GameObject {
  constructor(x, y, radius = 40) {
    super(x, y)
    this.radius = radius
  }

  initialize() {
    this.dir = Math.random() * 360
    this.speed = Math.random() * 200 + 200
  }

  get hSpeed() {
    return xOrd(this.speed, this.dir)
  }

  get vSpeed() {
    return yOrd(this.speed, this.dir)
  }

  split() {
    if (this.radius > 10) {
      [1,2,3].forEach(() => {
        this.createObject(new Asteroid(this.x, this.y, this.radius / 2))
      })
    }
    this.destroy()
  }

  onStep = () => {
    this.x += this.hSpeed / this.fps
    this.y += this.vSpeed / this.fps

    screenWrap(this)
  }

  onDraw = (ctx) => {
    ctx.beginPath()
    ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI)
    ctx.fillStyle = "#333"
    ctx.fill()
    ctx.strokeStyle = "#aaa"
    ctx.lineWidth = 3
    ctx.stroke()
  }
}

export default Asteroid
