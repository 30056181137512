import GameObject from '../game-object'

class Explosion extends GameObject {
  initialize() {
    this.size = 0
  }

  onStep() {
    this.size += 10000 / (5 + this.size) / this.fps

    if (this.size > 100) {
      this.destroy()
    }
  }

  onDraw(ctx) {
    ctx.beginPath()
    ctx.arc(this.x, this.y, this.size, 0, 2 * Math.PI)
    ctx.fillStyle = "#FB7D37"
    ctx.globalAlpha = Math.max(0, (100 - this.size) / 100)
    ctx.fill()
  }
}

export default Explosion
