import React from 'react'
import * as styles from 'src/css/icon-button.module.css'
import clsx from 'clsx'

const IconButton = (props) => {
  const {className, ...other} = props

  return (
    <button className={clsx(className, styles.iconButton)} {...other} />
  )
}

export default IconButton