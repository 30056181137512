import GameController from './game-contoller'
import {Asteroid, Message, RocketShip, Score, Lives} from 'src/components/asteroids/objects'

class AsteroidsController extends GameController {
  constructor(canvas) {
    super(canvas, [
      new Message(0,0),
      new Score(0,0),
      new Lives(0,0),
      new RocketShip(canvas.offsetWidth / 2, canvas.offsetHeight / 2),
    ]);

    this.score = 0
    this.lives = 3
    this.level = 1

    this.awardedLives = 0
  }

  beforeUpdate() {
    if (!this.objects.some(o => o.constructor === Asteroid)) {
      for (let i = 0; i < this.level + 2; i++) {
        this.addObject(new Asteroid(-99,-99))
      }
      this.level += 1
    }

    if (this.score >= ((this.awardedLives + 1) * 2000)) {
      this.lives += 1
      this.awardedLives += 1
    }
  }
}

export default AsteroidsController