import React, {useRef, useEffect} from 'react'
import * as styles from 'src/css/asteroids.module.css'
import AsteroidsController from 'src/components/asteroids/asteroids-controller'

const Index = () => {
  const canvasElm = useRef()
  useEffect(() => {
    const canvas = canvasElm.current
    const canvasRect = canvas.getBoundingClientRect()
    canvas.width = canvasRect.width * window.devicePixelRatio
    canvas.height = canvasRect.height * window.devicePixelRatio
    const controller = new AsteroidsController(canvas)
    return () => {
      controller.stop()
    }
  }, [])

  return (
    <>
      <canvas ref={canvasElm} className={styles.canvas} aria-label="404 Not Found" />
    </>
  )
}

export default Index
