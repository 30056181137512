class GameController {
  constructor(canvas, objects) {
    this.canvas = canvas
    this.objects = objects

    this.buffer = document.createElement('canvas')
    this.buffer.width = canvas.width
    this.buffer.height = canvas.height

    const ctx = this.buffer.getContext('2d', {alpha: false})
    const screenCtx = canvas.getContext('2d', {alpha: false})

    this.objects.forEach(o => o.controller = this)
    this.objects.forEach(o => o.initialize())

    this.scale = window.devicePixelRatio

    this.pressedKeys = {}
    const keyDown = (e) => {
      this.pressedKeys[e.key] = true
      e.preventDefault()
    }
    const keyUp = (e) => {
      this.pressedKeys[e.key] = false
      e.preventDefault()
    }
    window.addEventListener('keydown', keyDown)
    window.addEventListener('keyup', keyUp)

    this.running = true

    const update = () => {
      if (this.running) {
        this.currentStepAt = new Date()
        this.stepTime = this.prevStepAt ? (this.currentStepAt - this.prevStepAt) : 17

        this.beforeUpdate()

        this.objects.forEach(object => object._handleStep())

        ctx.fillStyle = "#000";
        ctx.fillRect(0,0, this.buffer.width, this.buffer.height)
        this.objects.forEach(object => {
          ctx.scale(this.scale, this.scale)
          object.onDraw(ctx)
          ctx.setTransform(1,0,0,1,0,0);
          ctx.globalAlpha = 1
        })
        screenCtx.drawImage(this.buffer, 0, 0)

        this.prevStepAt = this.currentStepAt

        window.requestAnimationFrame(update)
      }
    }

    update()

    this.stop = () => {
      this.running = false;
      window.removeEventListener('keydown', keyDown)
      window.removeEventListener('keyup', keyUp)
    }
  }

  get fps() {
    return 1000 / this.stepTime
  }

  beforeUpdate() {}

  addObject = (obj) => {
    obj.controller = this
    obj.initialize()
    this.objects = [...this.objects, obj]
  }

  removeObject = (obj) => {
    this.objects = this.objects.filter(o => o !== obj)
  }
}

export default GameController