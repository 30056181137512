class GameObject {
  constructor(x,y) {
    this.x = x
    this.y = y
    this.rotate = 0
  }

  get canvas() {
    return this.controller.canvas
  }

  get viewWidth() {
    return this.canvas.width / this.controller.scale
  }

  get viewHeight() {
    return this.canvas.height / this.controller.scale
  }

  get pressedKeys() {
    return this.controller.pressedKeys
  }

  get fps() {
    return this.controller.fps
  }

  createObject = (obj) => {
    this.controller.addObject(obj)
  }

  destroy = () => {
    this.controller.removeObject(this)
  }

  _handleStep(context) {
    this.onStep()

    this.prevX = this.x
    this.prevY = this.y
  }
  initialize() {}
  onStep() {}
  onDraw(ctx) {}
}

export default GameObject