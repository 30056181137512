import GameObject from '../game-object'

class Score extends GameObject {
  onDraw(ctx) {
    ctx.font = "bold 18px Montserrat"
    ctx.fillStyle = "#aaa"
    const text = `Score: ${this.controller.score}`
    const width = ctx.measureText(text).width
    ctx.fillText(text,this.viewWidth - width - 40, this.viewWidth >= 1024 ? 40 : (this.viewHeight - 40))
  }
}

export default Score
